import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UnsavedChangesResponse } from '@core/interfaces/unsaved-changes.interface';
import { InactivityService } from '@core/services/inactivity.service';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesWarningService {
  public tenantSettingEnabled: boolean;
  protected destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly translateService: TranslateService,
    private readonly inactivityService: InactivityService,
  ) {}

  /**
   * Did the user make any changes to the form in this component? UnsavedChangesGuards will call this
   * method and determine whether the user should get a dialogue to confirm if they want to
   * leave the page despite there being unsaved changes
   */
  public defaultHasUnsavedChanges(form: UntypedFormGroup, submitting = false): UnsavedChangesResponse {
    return {
      showDialog: form?.dirty && form?.touched && !submitting,
      warningMessage:
        form?.dirty && !submitting ? this.translateService.instant('general.unsaved-changes-warning') : '',
    };
  }

  public getResponse($event: any, form: UntypedFormGroup): string | boolean {
    if (!this.inactivityService.userIsActive) {
      // If the user is logged out due to inactivity, we don't need to warn about unsaved changes
      return;
    }

    const { showDialog, warningMessage }: UnsavedChangesResponse = this.defaultHasUnsavedChanges(form);

    // Modern browsers display a default message
    if (showDialog) {
      $event.returnValue = warningMessage;
    }

    return showDialog ? $event.returnValue : false;
  }

  public handleBrowserRefresh(form: UntypedFormGroup, $event: any): string | boolean {
    return !this.getResponse($event, form);
  }

  public triggerPopUp(response: UnsavedChangesResponse): boolean {
    const { showDialog, warningMessage }: UnsavedChangesResponse = response;

    return showDialog ? confirm(warningMessage) : true;
  }
}
